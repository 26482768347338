import * as styles from './index.module.scss';

import { Link, graphql } from 'gatsby';

import { GatsbyImage } from 'gatsby-plugin-image'
import Layout from "../../components/layout";
import React from "react";
import Seo from "../../components/seo";

const NotFoundPage = ({ data }) => {
  return (
    <Layout>
      <Seo title="Fehler 404: Seite nicht gefunden"/>
      <div className={styles.wrapper}>
        <h1 className={styles.title}>Fehler 404: Seite nicht gefunden</h1>
        <p className={styles.message}>Die von Ihnen angeforderte Seite konnte leider nicht gefunden werden. <Link to='/'>Hier</Link> gelangen Sie zurück zur Startseite.</p>
        {( data && data.contentfulAsset &&
          <div className={styles.image}>
            <GatsbyImage image={data.contentfulAsset.gatsbyImageData}/>
          </div>
        )}
      </div>
    </Layout>
  )
}

export default NotFoundPage;

export const query = graphql`
  {
    contentfulAsset(contentful_id: {eq: "4cefqGInOjPEwuEBi4F1Nd"}) {
      id
      gatsbyImageData(
        layout: FULL_WIDTH
        placeholder: DOMINANT_COLOR
      )
    }
  }
`;
